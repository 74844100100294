/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

$(".button__steps").each(function() {
if (this.href === window.location.href) {
    $(this).addClass("active");
    }
});

$('.tabs--menu-item').click(function (){
  $('.tabs--content-item').removeClass('active');
  $('.tabs--menu-item').removeClass('active');
   var currentClass = $(this).attr('class');
   var section = currentClass.replace('tabs--menu-item ', '');
   var section = section.replace('active ', '');
  $('.tabs--content-item.'+ section).addClass('active');
  $('.tabs--menu-item.'+ section).addClass('active');
});

$('.step-expand__item-title').click(function (){
  $(this).parent().parent().toggleClass('active');
});


var $root = $('html, body');
$('.menu a').click(function() {
    $root.animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top
    }, 500);
    return false;
});


document.querySelector( '#nav-toggle' )
  .addEventListener( 'click', function() {
    this.classList.toggle( 'active' );
    document.querySelector( '#menu' ).classList.toggle( 'active' );
  });

$("#button").click(function(){
    $.post('https://test.salesforce.com/services/oauth2/token',
    {
        name: "Donald Duck",
        city: "Duckburg"
    },
    function(data, status){
        alert("Data: " + data + "\nStatus: " + status);
    });
});





})(jQuery); // Fully reference jQuery after this point.






